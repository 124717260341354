import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import lfirst from '../components/main-page'
import lsecond from '../components/second-page'
import lthird from '../components/third-page'
import lfour from '../components/material-page'
import lfive from '../components/registration-page'
import lsix from '../components/authorization-page'
import lseven from '../components/secure-page'
import leight from '../components/user-cabinet'

Vue.use(Router);

let router = new Router( {
    routes: [
        {
            path: '/',
            name: 'main',
            component: lfirst
        },
        {
            path: '/second-page',
            name: 'second',
            component: lsecond
        },
        {
            path: '/user',
            name: 'third',
            component: lthird
        },
        {
            path: '/material',
            name: 'material',
            component: lfour,
            meta: {requiresAuth: true},
        },
        {
            path: '/registr',
            name: 'registr',
            component: lfive,
            meta: { guest: true },
        },
        {
            path: '/auth',
            name: 'authorization',
            component: lsix,
            meta: { guest: true },
        },
        {
            path: '/secure',
            name: 'secure',
            component: lseven
        },
        {
            path: '/user-cabinet',
            name: 'user-cabinet',
            component: leight
        }
    ]
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isAuthenticated) {
        next()
        return
      }
      next('/secure')
    } else {
      next()
    }
  });

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
          next("/posts");
          return;
        }
        next();
    } else {
        next();
    }
});

export default router;