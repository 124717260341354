import axios from 'axios';

const state = {
    user: null,
};
const getters = {
    isAuthenticated: state => !!state.user,
    StatePosts: state => state.posts,
    StateUser: state => state.user,
};
const actions = {
    async Register(form) {
        await axios.post( 'http://localhost:8000/post', form)
        let UserForm = new FormData()
        UserForm.append('username', form.username)
        UserForm.append('password', form.password)
        UserForm.append('confirm_password', form.confirm_password)
        UserForm.append('email', form.email)
      },

      async LogIn({commit}, User) {
        await axios.post('http://localhost:8000/auth', User)
        await commit('setUser', User.get('username'))
      },

      async LogOut({commit}){
        let user = null
        commit('LogOut', user)
      },

      async Example(){
        console.log(state.user)
      }
};
const mutations = {

    setUser(state, username){
        state.user = username
    },
    setPosts(state, posts){
        state.posts = posts
    },
    LogOut(state){
        state.user = null
    },
};
export default {
  state,
  getters,
  actions,
  mutations
};
