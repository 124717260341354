<template>
    <div>
        <div class="MainContent">
            <h1>Я {{ message }} страница</h1>
        </div>
        <div class="workzone">
            <h1>Уважаемый пользователь</h1>
            <p>На данный момент сайт находиться в стадии разработки, поэтому в нём не хватает наполнения, а также довольно ограничен функционал</p>
            <p>Рекомендуется ознакомиться с рекомендациями и списком доступных функций для наиболее комфортной и продуктивной работы со словарём</p>
            <div class="workzone-table">
                <div class="workzone-table--list">
                    <b-table striped hover :items="items"></b-table>
                </div>
                <div class="workzone-table--list">
                    <b-table striped hover :items="recommended"></b-table>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import { BTable } from 'bootstrap-vue'

export default {
    name: 'third-page',
    components: { BTable },
    data (){
        return {
            message: 'третья',
            fields: ['Функции', 'Рекомендации'],
            items: [
                { 'Функции': '1. Поиск искомого слова по базе данных' },
                { 'Функции': '2. Вывод профессии, где использовалось слово' },
                { 'Функции': '3. Вывод области, в которой использовалось слово' },
                { 'Функции': '4. Возможность регистрации' },
                { 'Функции': '5. Предоставление материалов (только зарегистрированным пользователям)' },
            ],
            recommended: [
                { 'Рекомендации': '1. Вводите искомое слово на русском языке' },
                { 'Рекомендации': '2. Не допускайте ошибок в набранном слове' },
                { 'Рекомендации': '3. Не используйте латинских символов при поиске' },
                { 'Рекомендации': '4. Не ставьте ".", ":" или ";" в конце слова' },
                { 'Рекомендации': '5. Не пишите два слова одновременно' },
            ]
        }
    }
}
</script>

<style scoped>

.workzone-table--list:hover{
    box-shadow: 0 0 10px rgb(24, 23, 23);
    transition: 0.5s;
    transform: translateY(-5px);
}

</style>
