<template>
    <div>
        <div>
            <h1>Я {{ message }} страница</h1>
            <div class="workzone">
                <div class="workzone-table">
                    <!-- Область с поисковой строкой -->
                    <div class="container--search">
                        <p>Введите слово для поиска:</p>
                            <b-form-input id="input-1" v-model="word" type="text" placeholder="Введите слово" required>
                            </b-form-input> <button type="submit" class="btn fifth" @click="search">Поиск</button>
                    </div>
                    <!-- Область с фильтрами -->
                    <div class="container--filter">
                        <p> Профессия: </p>
                        <b-form-select v-model="selected" class="mb-3">
                            <b-form-select-option :value="null"> Не показывать</b-form-select-option>
                            <b-form-select-option value="1">Показывать</b-form-select-option>
                        </b-form-select>
                        <p> Область применения: </p>
                        <b-form-select v-model="chosen" class="mb-3">
                            <b-form-select-option :value="null">Не показывать</b-form-select-option>
                            <b-form-select-option value="1">Показывать</b-form-select-option>
                        </b-form-select>
                    </div>
                    <!-- Область вывода результатов -->
                    <div class="container--bottom">
                        Перевод: {{ translatedWord }} {{ errors }} {{ profession }} {{ area }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {BFormInput, BFormSelectOption, BFormSelect} from 'bootstrap-vue'

export default {
    name: 'main-page',
    components: {BFormInput, BFormSelectOption, BFormSelect},
    data(){
        return {
            message: 'первая',
            word: null,
            translatedWord: null,
            errors: null,
            selected: null,
            chosen: null,
            profession: null,
            area: null,
        }
    },
    methods: {
        async search () {
            try {
                const search = await this.axios.post( 'http://localhost:8000/search', {
                    word: this.word,
                    selected: this.selected,
                    chosen: this.chosen,
                })
                this.errors = " ";
                this.profession = " ";
                this.area = " ";
                this.translatedWord = search.data.translate;
                if (Object.prototype.hasOwnProperty.call(search.data, 'profession')) {
                    this.profession = search.data.profession
                }
                if (Object.prototype.hasOwnProperty.call(search.data, 'area')) {
                    this.area = search.data.area
                }
            } catch (error) {
                this.translatedWord = " ";
                this.errors = error.response.data.errors.word[0];
            }
        },
    }
}
</script>

<style scoped>
.workzone{
    width: 1500px;
    height: 500px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    padding: 10px;
    font-size: 20px;
    box-shadow: 0 0 15px rgb(24, 23, 23);
    /* background-color: white; Включить при задании задневого фона картинкой  */
}
.workzone-table{
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    width: auto;    /* Подправил 100% на auto */
    height: auto;   /* Подправил 300px на auto */
    padding: 20px;
}
.container--search{
    max-width: 600px;
    height: auto;
    padding: 10px;
    background-color: rgb(20, 172, 20);
    font-size: 24px;
    border-radius: 2%;
}
.container--filter{
    max-width: 300px;
    height: auto;
    padding: 10px 40px;
    font-size: 24px;
    margin: 0;
    background-color: rgb(20, 172, 20);
    border-radius: 2%;
}
.container--filter select{
    display: inherit;
    justify-content: left;
    font-size: 20px;
    outline: none;
    border: none;
    border-radius: 5%;
}
p{
    text-align: left;
}
.container--bottom{
    position: absolute;
    bottom: 10%;
    width: 70%;
    height: auto;
    text-align: left;
}

/* Стиль для кнопки */
.btn{
    width: 80px;
    height: 2rem;
    font-size: 20px;
    font-weight: 400;
    margin: auto 10px;
    line-height: 1;
    cursor: pointer;
    outline: none;
}
.btn:hover, .btn:focus {
  color: black;
  outline: 0;
}
.fifth {
  border-color: #8e44ad;
  border-radius: 0;
  color: #8e44ad;
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: color 150ms ease-in-out;
  transition: color 150ms ease-in-out;
}
.fifth:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  height: 100%;
  background: #8e44ad;
  z-index: -1;
  -webkit-transition: width 150ms ease-in-out;
  transition: width 150ms ease-in-out;
}
.fifth:hover {
  color: #fff;
}
.fifth:hover:after {
  width: 110%;
}
/* Конец стиля для кнопки */

@media only screen and (max-width: 1360px){
    .workzone{
        width: 80%;
    }
}
@media only screen and (max-width: 1080px) {
    .workzone{
        height: auto;
    }
    .workzone-table{
        flex-direction: column;
        align-items: center;
    }
    .container--filter{
        max-width: 500px;
        font-size: 22px;
        margin-top: 2%;
    }
    .container--bottom{
        position: static;
        width: 80%;
        margin-top: 4%;
    }

}
</style>