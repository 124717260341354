<template>
    <div>
        <div>
            <h1>Регистрация</h1>
            <div class="workzone">
                <div class="workzone-table">
                    <div class="workzone-table--auth">
                        <p>Введите имя: </p>
                        <input required v-model="form.username" placeholder="Имя" autofocus>
                        <p>Введите пароль: </p>
                        <input required v-model="form.password" type="password" placeholder="Пароль">
                        <p>Подтвердите пароль: </p>
                        <input required v-model="form.confirm_password" type="password" placeholder="Подтвердите пароль">
                        <p>Введите почту: </p>
                        <input required v-model="form.email" placeholder="Email">
                        <p>  </p>
                        <button type="submit" @click="searching" class="btn fifth">Зарегистрироваться</button>
                        <p>Уже зарегистрированы? <router-link :to="{ name: 'authorization' }"> войти </router-link> </p> 
                    </div>
                    <!-- <div class="feedback">
                        <textarea v-model="feedback" placeholder="введите несколько строчек"></textarea>
                        <button type="submit" @click="submit">Submit</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    components: {},
    data(){
        return {
            form: {
                username: null,
                password: null,
                confirm_password: null,
                email: null,
            }
        }
    },
    methods: {
        ...mapActions(["Register"]),
       async searching () {
            try {
                await this.Register(this.form);
                this.$router.push('/user-cabinet');
                this.showError = false
            } catch (error) {
                console.log(error)
            }
        },

        //Отправка отзыва в БД
        // submit () {
        //     try {
        //         const example = this.axios.post( 'http://localhost:8000/submit', {
        //             body: this.feedback,
        //         })
        //         console.log(example)
        //     } catch (error) {
        //         console.log(error)
        //     }
        // }
    },
}
</script>

<style scoped>
.workzone{
    width: 1500px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    padding: 10px;
    font-size: 20px;
    box-shadow: 0 0 15px rgb(24, 23, 23);
    /* background-color: white; Включить при задании задневого фона картинкой  */
}
.workzone-table{
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    width: auto;    /* Подправил 100% на auto */
    height: auto;   /* Подправил 300px на auto */
    padding: 20px;
}
.workzone-table--auth{
    width: 400px;
    height: auto;
    border-radius: 10px;
    border: 1px solid black;
}

/* Общий стиль */
input{
    font-size: 20px;
}
/* Конец общего стиля */

/* Стиль для кнопки */
.btn{
    width: auto;
    height: 44px;
    font-size: 20px;
    font-weight: 400;
    margin: auto 10px;
    line-height: 1;
    cursor: pointer;
    outline: none;
}
.btn:hover, .btn:focus {
  color: black;
  outline: 0;
}
.fifth {
  border-color: #8e44ad;
  border-radius: 0;
  color: #8e44ad;
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: color 150ms ease-in-out;
  transition: color 150ms ease-in-out;
}
.fifth:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  height: 100%;
  background: #8e44ad;
  z-index: -1;
  -webkit-transition: width 150ms ease-in-out;
  transition: width 150ms ease-in-out;
}
.fifth:hover {
  color: #fff;
}
.fifth:hover:after {
  width: 110%;
}
/* Конец стиля для кнопки */


@media only screen and (max-width: 1360px){
    .workzone{
        width: 80%;
    }
}

/* For feedback 
 .feedback{
    display: inherit;
    flex-direction: column;
} */
</style>