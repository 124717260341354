<template>
    <div>
        <div class="MainContent">
            <h1>Я {{ message }} страница</h1>
        </div>
    </div>
</template>



<script>
export default {
    name: 'second-page',
    data (){
        return {
            message: 'вторая',
        }
    }
}
</script>