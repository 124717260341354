<template>
<div>
    <div>
        <div class="header">
            <img :src=" require('../assets/image/slack.svg') " alt="Логотип">
            <p>Русско-Офенский словарь</p>
        </div>
        <!-- Навигационная панель -->
        <b-navbar toggleable="lg" type="dark" variant="dark" sticky>
            <b-navbar-brand :to="{ name: 'main' }">Главная</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item :to="{ name: 'second' }">Страница №2</b-nav-item>
                <b-nav-item :to="{ name: 'third' }">Пользователю</b-nav-item>
                <b-nav-item :to="{ name: 'material' }">Материалы</b-nav-item>
            </b-navbar-nav>

            <b-navbar-nav class="ms-auto">
                <!-- Using 'button-content' slot -->
                <b-nav-item-dropdown right>
                <template #button-content>
                    <em>User</em>
                </template>
                <span v-if="isLoggedIn">
                    <b-dropdown-item :to="{ name: 'user-cabinet' }">Profile</b-dropdown-item>
                    <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
                </span>
                <span v-else>
                    <b-dropdown-item :to="{ name: 'registr' }">Регистрация</b-dropdown-item>
                </span>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</div>
</template>

<style scoped>
    .navbar-brand{
        margin-left: 2rem;
    }
    .navbar-nav{
        margin-left: 2rem;
    }
    .ms-auto{
        margin-right: 10%;
    }
    .header{
        display: flex;
        justify-content: flex-start;
        padding-left: 10%;
        column-gap: 50px;
        align-items: center;
        font-size: 30px;
        width: auto;
        height: 80px;
        background-color: rgba(0, 128, 0, 1);
        top: 0;
    }
    .header img{
        width: auto;
        height: 70px;
    }
</style>

<script>

import { BNavbar, BNavbarNav, BNavItem, BCollapse, BNavbarToggle, BNavItemDropdown, BDropdownItem, BNavbarBrand } from 'bootstrap-vue'

export default {
    name: 'HeaderContent',
    components: { BNavbar, BNavbarNav, BNavItem, BCollapse, BNavbarToggle, BNavItemDropdown, BDropdownItem, BNavbarBrand },

    computed : {
      isLoggedIn : function(){ return this.$store.getters.isAuthenticated}
    },
    methods: {
        async logout (){
        await this.$store.dispatch('LogOut')
        this.$router.push('/')
      }
    },
}
// BDropdownItem
</script>