<template>
  <div>
    <div class="workzone">
      <h1>Простите, но доступ к этой странице разрешён только аутентифицированным пользователям, пройдите процесс регистрации или войдите, чтобы увидеть содержимое</h1>
    </div>
  </div>
</template>

<script>
export default{
  name: "secure",
  data(){
    return{}
  },
  methods:{
  }
}
</script>

<style scoped>
.workzone{
    width: 1500px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    padding: 10px;
    font-size: 20px;
    box-shadow: 0 0 15px rgb(24, 23, 23);
    /* background-color: white; Включить при задании задневого фона картинкой  */
}
@media only screen and (max-width: 1360px){
  .workzone{
      width: 80%;
  }
}

</style>
