<template>
  <div>
    <HeaderContent />
      <div id="app">
        <router-view></router-view>
        <!-- <img alt="Vue logo" src="./assets/logo.png">
        <HelloWorld msg="Welcome to Your Vue.js App"/> -->
      </div>
    <FooterContent />
  </div>
</template>

<script>
// Импорт "Шапки" и "Подвала" сайта
import HeaderContent from './components/HeaderContent.vue'
import FooterContent from './components/FooterContent.vue'

// Импорт стилей для бутстрапа
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  name: 'App',
  components: {
    HeaderContent,
    FooterContent,
  }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
  /* background-image: url("./assets/image/60.jpg"); Рассмотреть фон на сайте */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  margin-bottom: 120px;
}
/*
.navbar{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  padding: 15px;
  font-size: 20px;
  background-color: black;
  position: sticky;
} */
</style>
