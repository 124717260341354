<template>
    <div>
        <div class="MainContent">
            <h1>Я {{ message }} страница</h1>
        </div>

        <div class="workzone">
            <div class="workzone-material">
                <div class="workzone-material--list">
                    <img src="../assets/image/book.svg" alt="книжка">
                    <p><b>­«Тайные и условные языки в России XIX В. Приёмышева М.Н.»</b></p>
                </div>
                <div class="workzone-material--list">
                    <img src="../assets/image/book.svg" alt="книжка">
                    <p><b>­«Условные языки русских ремесленников и торговцев. Бондалетов В.Д.»</b></p>
                </div>
                <div class="workzone-material--list">
                    <img src="../assets/image/book.svg" alt="книжка">
                    <p><b>­«Теория языка. Бондалетов В.Д.»</b></p>
                </div>
                <div class="workzone-material--list">
                    <img src="../assets/image/book.svg" alt="книжка">
                    <p><b>­«Библиография»</b></p>
                </div>
                <div class="workzone-material--list">
                    <img src="../assets/image/book.svg" alt="книжка">
                    <p><b>­«Библиография»</b></p>
                </div>
                <div class="workzone-material--list">
                    <img src="../assets/image/book.svg" alt="книжка">
                    <p><b>­«Библиография»</b></p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'material-page',
    data() {
        return{
            message: 'четвёртая'
        }
    }
}
</script>

<style scoped>
.workzone{
    width: 1500px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    padding: 10px;
    font-size: 20px;
    box-shadow: 0 0 15px rgb(24, 23, 23);
    /* background-color: white; Включить при задании задневого фона картинкой  */
}
.workzone-material{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
    width: auto;
    height: auto;
    padding: 10px;
    /* border: 1px solid black; */
}
.workzone-material--list{
    width: 250px;
    height: auto;
    transition: 0.5s;
    border: 1px groove;
}
.workzone-material--list img{
    width:100px;
    height:100px;
    margin-top: 30px;
}
.workzone-material--list:hover{
    box-shadow: 0 0 10px rgb(24, 23, 23);
    transition: 0.5s;
    transform: translateY(-5px);
}
@media only screen and (max-width: 1360px){
    .workzone{
        width: 80%;
    } 
}
@media screen and (max-width: 650px){
    .workzone-material{
        flex-direction: column;
        align-items: center;
    }
}
</style>