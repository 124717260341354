<template>
  <div>
    <h1>Я {{ message }} до определённых пор страница</h1>
    <div class="workzone">
      <div class="workzone-table">
        <b-avatar variant="secondary" size="6rem"></b-avatar>
        <!-- <img src="../assets/image/profile.png" alt="text"> -->
        <p> Добро пожаловать, {{ User }} </p>
        <div class="list-item">
          В данном окне будет выводиться список добавленной литературы.
        </div>
        <button class="btn fifth" @click="fokus()">Кнопка</button>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import { mapGetters} from "vuex"

export default{
  name: 'user-cabinet',
  components: { BAvatar },
  data(){
    return{
      message: 'скрытая',
      username: this.$store.state.username,
    }
  },
  methods:{
  },
  computed: {
    ...mapGetters({ User: "StateUser" }),
  }
}
</script>

<style scoped>
.workzone-table{
    flex-direction: column;
}
</style>
